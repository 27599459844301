<template src="./Certificates.html"></template>

<script>
export default {
  name: "Certificates",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    viewAndDownload() {
      window.open(this.data.url, "_blank");
    },
  },
};
</script>

<style scoped lang="scss" src="./Certificates.scss">
</style>
