const genderTransform = gender => {
  if (gender === 'M') {
    return 'Masculino';
  } else if (gender === 'F') {
    return 'Femenino';
  }
  return 'Otro';
}

export {
  genderTransform
}
