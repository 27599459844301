import { render, staticRenderFns } from "./Certificates.html?vue&type=template&id=5c37bf4c&scoped=true&"
import script from "./Certificates.vue?vue&type=script&lang=js&"
export * from "./Certificates.vue?vue&type=script&lang=js&"
import style0 from "./Certificates.scss?vue&type=style&index=0&id=5c37bf4c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c37bf4c",
  null
  
)

export default component.exports