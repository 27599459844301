<template src="./ProfileRecommendation.html">

</template>

<script>
import { PROFILE_IMAGEN } from '@/utils/constants';

export default {
  name: "ProfileRecommendation",
  data() {
    return {
      PROFILE_IMAGEN
    };
  },
  props:{
    data:{
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped lang="scss" src="./ProfileRecommendation.scss">

</style>
