import { nodeInstance } from "@/plugins/axios";

const recommendationsService = {};

recommendationsService.postRecommendation = obj => {
  return nodeInstance.post(`/api/recommendation`, obj);
};

recommendationsService.getRecommendation = () => nodeInstance.get(`/api/recommendations`);
recommendationsService.getRecommendationById = (id) => nodeInstance.get(`/api/recommendations/${id}`);

export default recommendationsService;
